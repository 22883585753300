import React from 'react'

import me from '../images/me.png'

import { rhythm } from '../utils/typography'

class Bio extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(1),
        }}
      >
        <img
          src={me}
          alt={`Rohit Mundra`}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: rhythm(2.5),
            height: rhythm(2.5),
            borderRadius: 200
          }}
        />
        <p style={{ fontSize: rhythm(0.65) }}>
          Based in India. Building a few helpful things. 
          Find me here talking about anything and everything.
          Keeping a track of my ideas so they don't get lost 
          in the void. <a href='https://twitter.com/brohit3' target='_blank'>
          Tweet</a> at me if you need me. 
        </p>
      </div>
    )
  }
}

export default Bio
