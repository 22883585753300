import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import _ from "lodash"

import { rhythm, scale } from '../utils/typography'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BackButton from '../components/back-button'
import Bio from '../components/bio'

export default function Template({ data }) {
    const post = data.markdownRemark
    const tags = post.frontmatter.tags

    const keywords = ['rohit mundra', 'blog'].concat(tags)

    return (
        <Layout>
            <SEO title={post.frontmatter.title} keywords={keywords} />
            <div>
                <BackButton to='/' title='Back' />
                <h1>{post.frontmatter.title}</h1>
                <h5>Posted on {post.frontmatter.date}</h5>
                <div style={{ fontSize: '1.1rem' }} dangerouslySetInnerHTML={{ __html: post.html }} />
                <div style={{marginBottom: rhythm(1)}}>
                    {post.frontmatter.tags.map(tag => (
                        <Link to={`/tags/${_.kebabCase(tag)}`}>
                            <span style={{ 
                                marginRight: '1rem'
                            }} 
                                className="label label-default"
                            >
                                { tag }
                            </span>
                        </Link>
                    ))}
                </div>
            </div>
            <hr />
            <h4>About the author</h4>
            <Bio />
        </Layout>
    )
}

export const postQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }){
            html
            frontmatter {
                path
                title
                author
                date(formatString: "MMMM Do, YYYY")
                tags
            }
        }
    }
`